// Variants
.hnui-badge-container {
  @apply relative flex max-w-[10rem] items-center transition duration-200;
}

.hnui-badge-variant-red {
  @apply inline-flex select-none items-center rounded-md bg-red3 font-medium  ;
}
.hnui-badge-variant-yellow {
  @apply inline-flex select-none items-center rounded-md bg-yellow3 font-medium ;
}
.hnui-badge-variant-green {
  @apply inline-flex select-none items-center rounded-md bg-green3 font-medium  ;
}
.hnui-badge-variant-gray {
  @apply inline-flex select-none items-center rounded-md bg-gray3 dark:bg-gray6 font-medium ;
}
.hnui-badge-variant-blue {
  @apply inline-flex select-none items-center rounded-md bg-blue3 font-medium ;
}
.hnui-badge-variant-indigo {
  @apply inline-flex select-none items-center rounded-md bg-indigo3 font-medium ;
}
.hnui-badge-variant-purple {
  @apply inline-flex select-none items-center rounded-md bg-purple3 font-medium ;
}
.hnui-badge-variant-orange {
  @apply inline-flex select-none items-center rounded-md bg-orange3 font-medium ;
}
.hnui-badge-variant-rose {
  @apply inline-flex select-none items-center rounded-md bg-pink3 font-medium ;
}
.hnui-badge-variant-teal {
  @apply inline-flex select-none items-center rounded-md bg-teal3 font-medium ;
}
.hnui-badge-variant-violet {
  @apply inline-flex select-none items-center rounded-md bg-violet3 font-medium ;
}
.hnui-badge-variant-cyan {
  @apply inline-flex select-none items-center rounded-md bg-cyan3 font-medium ;
}
.hnui-badge-variant-outline {
  @apply inline-flex select-none items-center rounded-md border border-gray6 bg-snow dark:bg-gray3 dark:hover:bg-gray4 font-medium  hover:bg-gray3   ;
}
.hnui-badge-variant-default {
  @apply inline-flex select-none items-center rounded-md bg-primary font-medium dark:bg-primary-light;
}

.hnui-badge-color-red {
  @apply text-red11 ;
}
.hnui-badge-color-yellow {
  @apply text-yellow11 ;
}
.hnui-badge-color-green {
  @apply text-green11 ;
}
.hnui-badge-color-gray {
  @apply text-gray11 ;
}
.hnui-badge-color-blue {
  @apply text-blue11 ;
}
.hnui-badge-color-indigo {
  @apply text-indigo11  ;
}
.hnui-badge-color-purple {
  @apply text-purple11 ;
}
.hnui-badge-color-orange {
  @apply text-orange11 ;
}
.hnui-badge-color-rose {
  @apply text-pink11 ;
}
.hnui-badge-color-teal {
  @apply text-teal11 ;
}
.hnui-badge-color-violet {
  @apply text-violet11 ;
}
.hnui-badge-color-cyan {
  @apply text-cyan11 ;
}
.hnui-badge-color-outline {
  @apply text-gray11 dark:text-gray11 ;
}
.hnui-badge-color-default {
  @apply text-brand dark:text-brand;
}
// Bage Size

.hnui-badge-xxs {
  @apply px-[0.25rem] py-0.5 text-[10px];
}
.hnui-badge-xs {
  @apply py-0.5 px-2 text-xs;
}
.hnui-badge-sm {
  @apply px-2.5 py-0.5 text-sm;
}
.hnui-badge-md {
  @apply px-3.5 py-1 text-base;
}

.hnui-badge-rounded {
  @apply rounded-full;
}

.hnui-badge-close {
  @apply right-2 ml-1 h-3 w-3 shrink-0 cursor-pointer rounded-full;
}
.hnui-badge-value {
  @apply truncate px-1 font-medium;
}
.hnui-badge-cursor-pointer {
  @apply transform cursor-pointer;
}
