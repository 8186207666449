.hnui-contextmenu-content {
  @apply z-[6] my-2 max-h-[calc(100vh-100px)] w-40 min-w-[11rem] max-w-[12rem] origin-top-right overflow-y-auto rounded-md bg-snow py-1 font-medium shadow-lg ring-1 ring-carbon ring-opacity-5 duration-200 scrollbar-hide focus:outline-none dark:bg-gray3;
}

.hnui-contextmenu-trigger {
  @apply border-none p-0 focus:ring-0 focus-visible:bg-transparent focus-visible:outline-none;
}

.hnui-contextmenu-item {
  @apply flex w-full cursor-pointer select-none appearance-none items-center justify-start space-x-1.5 truncate px-3 py-1 text-left text-sm hover:bg-gray6 focus:bg-gray6 focus:outline-none;
}

.hnui-contextmenu-item[data-disabled] {
  @apply pointer-events-none text-gray8;
}

.hnui-contextmenu-label {
  @apply my-1 select-none truncate pl-3 text-sm font-medium text-gray12;
}

.hnui-contextmenu-separator {
  @apply my-1 h-px bg-gray5 transition;
}

.hnui-contextmenu-list-item {
  @apply flex w-full items-center justify-between;
}

.hnui-contextmenu-item-text {
  @apply flex w-full items-center space-x-2 overflow-hidden;
}

.hnui-contextmenu-item-text-default {
  @apply text-gray11 hover:text-gray12;
}

.hnui-contextmenu-item-text-red {
  @apply text-red11;
}
