.hnui-datepicker--xs {
  @apply px-2.5 py-[4px] text-xs;
}
.hnui-datepicker--sm {
  @apply px-3 py-2 text-sm leading-4;
}
.hnui-datepicker--md {
  @apply px-4 py-2 text-sm;
}
.hnui-datepicker--lg {
  @apply px-4 py-2 text-base;
}
.hnui-datepicker--xl {
  @apply px-6 py-3 text-base;
}
