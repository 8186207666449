.hnui-alert {
  @apply p-4;
}

.hnui-alert-success {
  @apply bg-green3 dark:bg-green6/30;
}
.hnui-alert-error {
  @apply bg-red3 dark:bg-red6/30;
}
.hnui-alert-warning {
  @apply bg-yellow3 dark:bg-yellow6/30;
}
.hnui-alert-info {
  @apply bg-blue3 dark:bg-blue6/30;
}
.hnui-alert-calender {
  @apply bg-blue3 dark:bg-blue6/30;
}

.hnui-alert-icon-size {
  @apply h-4 w-4;
}

.hnui-alert-icon-success {
  @apply text-green8;
}

.hnui-alert-icon-error {
  @apply text-red8;
}

.hnui-alert-icon-warning {
  @apply text-yellow8;
}

.hnui-alert-icon-info {
  @apply text-blue9;
}

.hnui-alert-message {
  @apply grow text-sm font-medium;
}

.hnui-alert-message-success {
  @apply text-green11;
}
.hnui-alert-message-error {
  @apply text-red11;
}
.hnui-alert-message-warning {
  @apply text-yellow11;
}
.hnui-alert-message-info {
  @apply text-blue10;
}

.hnui-alert-description {
  @apply mt-2 grow text-sm font-medium text-gray11;
}

.hnui-alert-description-success {
  @apply text-green10;
}
.hnui-alert-description-error {
  @apply text-red10;
}
.hnui-alert-description-warning {
  @apply text-yellow9;
}
.hnui-alert-description-info {
  @apply text-blue10;
}

.hnui-alert-action {
  @apply whitespace-nowrap font-medium transition-opacity duration-200 hover:opacity-70;
}
