@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.hnui-tooltip-content {
  @apply z-[6] max-w-xs break-words rounded-md bg-gray12/90 backdrop-blur py-1 px-2 text-center text-xs font-medium leading-[1.1rem] text-gray1 shadow-tooltip outline-none will-change-[transform,opacity];
}
