/* @keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translatey(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translatey(2px);
  }
  to {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
*/
.hnui-popover-content {
  @apply relative z-[6] max-h-96 overflow-x-auto rounded-[10px] border border-gray5 bg-snow p-3.5 text-xs font-medium leading-[1.1rem] text-gray9 shadow-lg will-change-[transform,opacity] focus-visible:outline-none dark:border-gray-dark-border dark:bg-gray3 dark:text-gray-light;
}
.hnui-popover-content[data-size='1'] {
  @apply w-60;
}
.hnui-popover-content[data-size='2'] {
  @apply w-80;
}
/*
.hnui-popover-content[data-state='open'] {
  animation-duration: 2s;
  animation-delay: 2s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}

.hnui-popover-content[data-side='top'] {
  animation-name: slideDownAndFade;
  animation-duration: 2s;
  animation-delay: 2s;
}
.hnui-popover-content[data-side='right'] {
  animation-name: slideLeftAndFade;
  animation-duration: 2s;
  animation-delay: 2s;
}
.hnui-popover-content[data-side='bottom'] {
  animation-name: slideUpAndFade;
  animation-duration: 2s;
  animation-delay: 2s;
}
.hnui-popover-content[data-side='left'] {
  animation-name: slideRightAndFade;
  animation-duration: 2s;
  animation-delay: 2s;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hnui-popover-content[data-state='open'] {
  animation: fadeIn 200ms ease-out;
}

.hnui-popover-content[data-state='closed'] {
  animation: fadeOut 100ms ease-in;
  animation: fadeIn 100ms ease-out;
}
*/
