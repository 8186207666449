.hnui-avatar--xxs {
  @apply h-4 w-4 text-[8px];
}
.hnui-avatar--xs {
  @apply h-6 w-6 text-[11px];
}

.hnui-avatar--sm {
  @apply h-8 w-8;
}

.hnui-avatar--md {
  @apply h-10 w-10;
}

.hnui-avatar--lg {
  @apply h-12 w-12 text-[20px];
}

.hnui-avatar--xl {
  @apply h-16 w-16 text-[30px];
}
