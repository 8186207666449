.hnui-dropdown-content {
  @apply z-[6] max-h-96 min-w-[11rem] max-w-[12rem] overflow-y-auto rounded-[10px] border border-gray5 bg-snow p-0.5 py-0.5 shadow-lg transition will-change-[transform,opacity] dark:bg-gray3;
}

.hnui-dropdown-trigger {
  @apply border-none bg-transparent p-0 focus:ring-0 focus-visible:bg-transparent focus-visible:outline-none;
}

.hnui-dropdown-item {
  @apply flex w-full cursor-pointer items-center justify-start space-x-1 truncate rounded-lg text-left text-sm outline-none duration-200 hover:bg-gray7 focus:bg-gray7 focus-visible:outline-none;
}
.hnui-dropdown-item[data-disabled] {
  @apply pointer-events-none text-gray7;
}

.hnui-dropdown-arrow {
  @apply fill-current stroke-current text-gray4 transition;
}

.hnui-dropdown-label {
  @apply my-1 select-none truncate pl-2 text-sm font-semibold text-gray11;
}

.hnui-dropdown-separator {
  @apply my-1 h-px bg-gray5 transition;
}

.hnui-dropdown-list-item {
  @apply flex w-full cursor-pointer select-none appearance-none items-center justify-start space-x-1.5 overflow-hidden rounded-md px-3 py-1.5 text-left text-sm text-gray11 hover:bg-gray4 hover:text-gray12 focus:bg-gray5 focus:outline-none  dark:hover:bg-gray6;
}

.hnui-dropdown-item-text {
  @apply flex w-full items-center overflow-hidden text-gray12;
}

.hnui-dropdown-item-text-default {
  @apply text-gray12 hover:text-gray12;
}

.hnui-dropdown-item-text-red {
  @apply text-red9;
}

.hnui-dropdown-item-disabled {
  @apply pointer-events-none opacity-50;
}
