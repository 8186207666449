.hnui-Select-content {
  @apply z-10 max-h-[calc(100vh-100px)] min-w-[11rem] origin-top-right overflow-y-auto rounded-md bg-snow shadow-md ring-1 ring-carbon ring-opacity-5 scrollbar-hide focus:outline-none dark:border-gray-dark-border dark:bg-gray3 md:max-h-[25rem];
}

.hnui-Select-trigger {
  @apply flex grow items-center justify-between space-x-2 truncate font-medium focus:outline-none;
}

.hnui-Select-item {
  @apply flex w-full cursor-pointer select-none appearance-none items-center justify-start space-x-1.5 overflow-hidden rounded-md px-3 py-1.5 text-left text-sm text-gray12 hover:bg-gray4 hover:text-gray12 focus:bg-gray5 focus:outline-none  dark:hover:bg-gray6;
}
.hnui-Select-item[data-disabled] {
  @apply pointer-events-none text-gray8;
}

.hnui-Select-arrow {
  @apply fill-current stroke-current text-gray11 transition;
}

.hnui-Select-label {
  @apply my-1 select-none truncate px-3 text-xs font-medium text-gray9;
}

.hnui-Select-separator {
  @apply my-1 h-px bg-gray-dark-border transition dark:bg-gray-dark;
}

.hnui-Select-list-item {
  @apply flex w-full items-center justify-between truncate;
}

.hnui-Select-item-text {
  @apply flex w-full items-center space-x-1 truncate font-medium;
}

.hnui-Select-blank {
  @apply border border-transparent bg-transparent transition duration-200 hover:border-gray5 focus:ring-0 focus-visible:bg-gray5 focus-visible:outline-none dark:focus-visible:bg-gray-dark-hover;
}

.hnui-Select-pilled {
  @apply rounded-full;
}

.hnui-Select-outline {
  @apply border border-gray7 shadow-sm focus:ring-0 focus-visible:bg-gray5 focus-visible:outline-none dark:focus-visible:bg-gray-dark-hover;
}

.hnui-Select-dashed {
  @apply border border-dashed border-gray5 shadow-sm focus:ring-0 focus-visible:bg-gray5 focus-visible:outline-none dark:focus-visible:bg-gray-dark-hover;
}

.hnui-Select--xs {
  @apply px-2.5 py-[4px] text-xs;
}
.hnui-Select--sm {
  @apply px-3 py-2 text-sm leading-4;
}
.hnui-Select--md {
  @apply px-4 py-2 text-sm;
}
.hnui-Select--lg {
  @apply px-4 py-2 text-base;
}
.hnui-Select--xl {
  @apply px-6 py-3 text-base;
}

.hnui-select-overlay {
  @apply fixed inset-0 z-[3] grid overflow-y-auto overflow-x-hidden bg-gray-dark/70 py-1 transition-opacity duration-200;
}

.hnui-select-content {
  @apply fixed left-1/2 top-1/3 z-[6] mx-auto h-fit max-h-96 w-11/12 max-w-sm -translate-x-1/2 overflow-y-auto overflow-x-hidden rounded-md border border-gray5 bg-snow px-0.5 pb-0.5 shadow-lg transition focus:outline-none dark:bg-gray3 dark:shadow-xl;
}

.hnui-select-overlay[data-state='open'],
.hnui-select-content[data-state='open'] {
  animation: fadeIn 200ms ease-out;
}

.hnui-select-overlay[data-state='closed'],
.hnui-select-content[data-state='closed'] {
  animation: fadeOut 200ms ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
