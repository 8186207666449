.hnui-header-wrapper {
  @apply sticky top-0 z-[2] border-b border-gray4/50 bg-layoutBase bg-opacity-50 bg-blend-overlay backdrop-blur-3xl transition  dark:bg-[#1c1d1f]/10;
  .hnui-header-innerwrapper {
    @apply mx-auto max-w-7xl;
    .hnui-header-brand {
      @apply flex w-full items-center justify-between px-2 py-2 md:px-4;
      .hnui-header-brand-logo-image {
        @apply h-8 w-8;
      }
      .hnui-header-brand-name {
        @apply mb-0 ml-2 truncate text-base font-semibold text-gray12;
      }
    }
    .hnui-header-modules {
      @apply flex items-center justify-center space-x-2 pt-1 md:px-3;
      .hnui-header-modules--nav {
        @apply flex w-full items-center justify-between py-0.5 pr-1;
        .hnui-header-modules--nav-wrap {
          @apply flex items-center overflow-x-auto scrollbar-hide md:justify-start;
        }
      }
    }
  }
}
 