.hnui-alert-overlay {
  @apply fixed inset-0 z-[6] bg-gray4/60 transition-opacity duration-200;
}
.hnui-alert-content {
  @apply fixed left-1/2 top-1/2 z-[5] flex max-h-[90vh] w-11/12 max-w-lg -translate-x-1/2 -translate-y-1/2 items-start justify-start space-x-2.5 overflow-hidden overflow-y-auto rounded-lg bg-snow px-5 pb-4 pt-5 shadow-lg transition focus:outline-none dark:bg-gray1 dark:shadow-xl;
  z-index: 100000;
}
.hnui-alert-icon {
  @apply flex h-6 w-6 shrink-0;
}
.hnui-alert-success {
  @apply h-full w-full text-green9;
}
.hnui-alert-danger {
  @apply h-full w-full text-red9;
}
.hnui-alert-info {
  @apply h-full w-full text-blue9;
}
.hnui-alert-confetti {
  @apply h-full w-full text-blue9;
}
.hnui-alert-actions {
  @apply mt-3.5 w-full  items-center space-x-2 sm:flex;
}
.hnui-alert-title {
  @apply text-base font-semibold leading-6 text-gray12;
}
.hnui-alert-description {
  @apply mt-1.5 space-y-3 text-sm text-gray11;
}
.hnui-alert-flex {
  @apply flex w-full flex-col items-start justify-start;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hnui-alert-overlay[data-state='open'],
.hnui-alert-content[data-state='open'] {
  animation: fadeIn 300ms ease-out;
}

.hnui-alert-overlay[data-state='closed'],
.hnui-alert-content[data-state='closed'] {
  animation: fadeOut 300ms ease-in;
}
