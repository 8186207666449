@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.hnui-hovercard {
  @apply z-10 w-80 rounded-md bg-snow p-3.5 text-xs font-medium leading-[1.1rem] text-gray12 shadow-tooltip will-change-[transform,opacity]  dark:bg-gray-dark dark:text-gray-light;
  animation: scaleIn 320ms cubic-bezier(0.16, 1, 0.3, 1);
}
