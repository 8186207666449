.hnui-line-header-indigo {
  @apply text-indigo10 ;
}
.hnui-line-header-blue {
  @apply text-blue10 ;
}
.hnui-line-header-pink {
  @apply text-pink9 ;
}
.hnui-line-header-green {
  @apply text-green9  ;
}
.hnui-line-header-red {
  @apply text-red10  ;
}
.hnui-line-header-gray {
  @apply text-gray10 ;
}
.hnui-line-header-lightgray {
  @apply text-gray8 ;
}
.hnui-line-header-default {
  @apply text-primary;
}

.hnui-line-header-align-start {
  @apply justify-start;
}
.hnui-line-header-align-center {
  @apply justify-center;
}
.hnui-line-header-align-end {
  @apply justify-end;
}
.hnui-line-header-align {
  @apply relative flex;
}

.hnui-line-header-start {
  @apply pr-2;
}
.hnui-line-header-center {
  @apply px-2;
}
.hnui-line-header-end {
  @apply pl-2;
}

.hnui-line-header {
  @apply flex space-x-1 bg-snow text-xs font-bold uppercase tracking-wider transition dark:bg-[#1c1d1f];
}
.hnui-line-container {
  @apply absolute inset-0 flex items-center rounded;
}
.hnui-line-line {
  @apply w-full border-t border-gray5 transition ;
}
