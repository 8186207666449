.hnui-dialog-overlay {
  @apply fixed inset-0 z-[6] grid overflow-y-auto overflow-x-hidden bg-gray12/50 py-1 transition-opacity duration-200 dark:bg-gray1/90;
}

.hnui-dialog-content {
  @apply pointer-events-auto fixed left-[50%] top-1/2 z-[6] mx-auto flex h-auto -translate-y-1/2  translate-x-[-50%] flex-col overflow-hidden bg-snow p-6 shadow-dialog dark:bg-gray3  md:max-h-[85vh] md:w-11/12 md:rounded-lg md:p-3;
}

.hnui-dialog-mode {
  @apply z-[6] transition-opacity duration-200;
}

.hnui-dialog-title {
  @apply mr-6 pr-2 text-base font-medium leading-6 text-gray12;
}

.hnui-dialog-description {
  @apply mb-4 mt-2 w-full whitespace-pre-line text-gray11;
}

.hnui-dialog-close {
  @apply absolute right-[50px] flex h-6 w-6 scale-100 cursor-pointer appearance-none items-center justify-center rounded-md bg-gray3 transition-all hover:scale-105 hover:bg-gray7 active:scale-95 dark:bg-gray1 dark:hover:bg-gray9 md:right-3 md:top-2;
}

.hnui-dialog-close-icon {
  @apply h-3.5 w-3.5 shrink-0 text-gray11;
}

/*
  dialog Mode
*/

.hnui-dialog-mode-full {
  @apply fixed top-0 max-h-[calc(100vh-0px)] min-h-[calc(100vh-0px)] w-full place-items-center overflow-y-auto bg-snow shadow-none dark:bg-gray3  md:min-w-[calc(100vw-0px)] md:max-w-[calc(100vw-0px)];
}

.hnui-dialog-mode-aside {
  @apply fixed left-0 top-0 h-screen min-w-[230px] max-w-[20rem] place-items-start overflow-hidden bg-snow dark:bg-gray3;
}

.hnui-dialog-mode-drawer {
  @apply fixed right-0 top-0 h-screen w-5/6 min-w-full max-w-[20rem] place-items-end overflow-y-auto bg-snow p-4 drop-shadow-2xl dark:bg-gray3  sm:min-w-[420px] lg:m-5 lg:h-[calc(100vh-40px)] lg:rounded-md;
}

/*
  dialog sizes
*/

.hnui-dialog-content-xs {
  @apply max-w-xs overflow-y-auto;
}
.hnui-dialog-content-sm {
  @apply max-w-md overflow-y-auto;
}

@media (max-width: 540px) {
  .hnui-dialog-content-sm {
    @apply min-w-[90%];
  }
}

.hnui-dialog-content-md {
  @apply max-w-2xl overflow-y-auto;
}
.hnui-dialog-content-lg {
  @apply max-w-4xl overflow-y-auto;
}
.hnui-dialog-content-xl {
  @apply mx-auto w-[calc(100vw-10px)] overflow-y-auto sm:max-w-6xl;
}
.hnui-dialog-content-full {
  @apply max-h-[calc(100vh-20px)] min-h-[calc(100vh-20px)] max-w-5xl overflow-y-auto;
}

// Animations
.hnui-dialog-content-animation {
@apply shadow shadow-red-500;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(35px);
    transform: translateX(35px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(35rem);
    transform: translateX(35rem);
  }
}

.hnui-dialog-overlay[data-state='open'],
.hnui-dialog-content[data-state='open'] {
  animation: fadeIn 200ms ease-out;
}

.hnui-dialog-overlay[data-state='closed'],
.hnui-dialog-content[data-state='closed'] {
  animation: fadeOut 200ms ease-in;
}

.hnui-dialog-mode-drawer[data-state='open'] {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.hnui-dialog-mode-drawer[data-state='closed'] {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
