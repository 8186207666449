.hnui-editor-container {
  @apply relative flex flex-col rounded-md bg-snow text-carbon transition duration-200 dark:bg-gray1;
}
.hnui-editor {
  @apply h-full w-full overflow-hidden caret-primary;
}

.hnui-editor-borderless {
  @apply border-0 border-transparent focus-within:ring-0;
  background: transparent !important;
}

.hnui-editor-variant-default {
  @apply border-gray5;
}

.hnui-editor-variant-yellow {
  @apply border-yellow8 focus-within:border-yellow8 focus-within:ring-1  focus-within:ring-yellow8 hover:border-yellow8;
}

.hnui-editor-menu-bar-container {
  @apply bottom-0 min-w-0 overflow-x-auto rounded-b-md bg-snow px-1 pb-1 pt-1.5 scrollbar-hide dark:bg-gray3   md:space-x-2;
}

.hnui-menu-bar {
  @apply flex space-x-1 overflow-x-auto sm:justify-center;
}

.hnui-menu-bar-item {
  @apply rounded p-1 focus:bg-gray5 focus:outline-none disabled:pointer-events-none disabled:opacity-50;
}

.hnui-menu-bar-button {
  @apply bg-snow text-gray11 hover:bg-gray4 hover:text-gray9 dark:bg-gray1 dark:hover:bg-gray6;
}

.hnui-menu-bar-button-active {
  @apply bg-gray5 text-gray12;
}
