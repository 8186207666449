.hnui-tabs-trigger[data-state='active'] {
  @apply -my-px border-b border-gray12 text-gray12;
}

.hnui-tabs-trigger {
  @apply -my-px flex items-center space-x-1 border-b border-transparent p-1 text-sm font-medium text-gray11 transition duration-200 hover:border-fosblue hover:text-fosblue focus-visible:outline-none;
}

.hnui-tabs-list {
  @apply flex space-x-3 border-b border-gray6 py-0;
}
