.hnui-avatar {
  @apply relative flex shrink-0 cursor-pointer select-none items-center justify-center object-cover object-center align-middle font-medium text-fosblue transition-colors duration-200;
}

.hnui-avatar-rounded {
  @apply rounded-full;
}

.hnui-avatar-squer {
  @apply rounded-md;
}

.hnui-avatar-img {
  @apply h-full max-w-full overflow-hidden;
}

.hnui-avatar-fallback {
  @apply flex h-full min-w-full items-center justify-center overflow-hidden bg-blue4;
}

.hnui-avatar--xxs {
  @apply h-5 w-5 text-[8px];
}
.hnui-avatar--xs {
  @apply h-6 w-6 text-[11px];
}

.hnui-avatar--sm {
  @apply h-8 w-8;
}

.hnui-avatar--md {
  @apply h-10 w-10;
}

.hnui-avatar--lg {
  @apply h-12 w-12 text-[20px];
}

.hnui-avatar--xl {
  @apply h-16 w-16 text-[30px];
}

.hnui-avatar-multi {
  @apply ring-2 ring-snow transition duration-200 hover:z-[2];
}
