.hnui-accordion-content[data-state='open'] {
  @apply animate-height-down;
  // animation: slideDown 200ms cubic-bezier(0.87, 0, 0.13, 1);
}
.hnui-accordion-content[data-state='close'] {
  @apply animate-height-up;
  // animation: slideUp 200ms cubic-bezier(0.87, 0, 0.13, 1);
}

.hnui-accordion-root {
  @apply w-[300px] rounded-md bg-snow shadow-md;
}

.hnui-accordion-item {
  @apply flex items-center justify-between overflow-hidden bg-transparent;
}

.hnui-accordion-trigger[data-state='open'] {
  .hnui-accordion-icon {
    @apply rotate-180 transition duration-200;
  }
  .hnui-accordion-content {
    animation: slideDown 200ms cubic-bezier(0.87, 0, 0.13, 1);
  }
}

.hnui-accordion-trigger[data-state='closed'] {
  .hnui-accordion-icon {
    @apply rotate-0 transition duration-200;
  }
}

.hnui-accordion-trigger {
  @apply flex w-full cursor-pointer items-center justify-between rounded py-1.5 text-gray11;
}
